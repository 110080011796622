import Header from '../components/nav bar/Header'
import Signin from '../components/sign in/SignIn'

export default function SignIn() {
    return (
    <div className='sign-in-cont'>
        <Header/>
        <Signin/>
    </div>
    );
}