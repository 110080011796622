import Header from '../components/nav bar/Header'


export default function Oreders() {
    return (
    <div>
        <Header/>
        <h2>Orders</h2>
    </div>
    );
}